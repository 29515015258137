<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="100 > 0">
            <template v-slot:default>
                <thead>
                <tr>
                    <th style="width: 20px;"></th>
                    <th>Grupo</th>
                    <th>Usuários</th>
                </tr>
                </thead>

                <tbody>
                    <tr v-for="(ug, i) in grupos.lista" :key="i">
                        <td class="pr-0">
                            <v-avatar size="40" :color="corAvatar(ug.grupo)">
                                <span class="white--text headline">{{ug.grupo.substr(0, 1)}}</span>
                            </v-avatar>
                        </td>
                        <td>
                            <router-link exact color="primary" v-if="permissao('usuario_grupo_abrir')" :to="`/usuario/grupo/gerenciar/${ug.id_grupo}`"><strong>{{ug.grupo}}</strong></router-link>
                            <strong v-else>{{ug.grupo}}</strong><br/>
                            <small><strong>ID:</strong> {{ug.id_grupo}}</small>
                        </td>
                        <td>{{ug.usuario_total}}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="grupos.total"  />
    </div>
</template>

<script>

import axios from "axios"
import Paginacao from '@/Views/Estrutura/Paginacao'
import {mapState} from "vuex"

export default {
    name: "GruposListar",
    props : ['filtro'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            grupos : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['baseUrl', 'paginationLimit'])
    },
    methods : {
        paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.baseUrl}usuario/grupo_listar`, {
                pagina,
                limit  : this.paginationLimit,
                offset : this.paginationLimit * pagina,
                busca  : this.filtro.busca || null
            }).then( (res) => {
                this.grupos = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
