<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2 hidden-xs-only">Filtrar</v-toolbar-title>
                    <v-spacer class="hidden-xs-only"/>
                    <v-row>
                        <v-col cols="12" md="6" offset-md="6"><v-text-field dense label="Buscar:" hide-details flat outlined v-model="filtro.busca" /></v-col>
                    </v-row>
                </v-toolbar>
                <GrupoListar :filtro="filtro" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import GrupoListar from "@/Views/Usuario/Component/GrupoListar"
    import {mapState} from 'vuex'

    export default {
        name: "Grupo",
        components: {GrupoListar},
        data() {
            return {
                filtro : {}
            }
        },
        computed : {
            ...mapState(['baseUrl'])
        },
        methods : {

        }
    }
</script>

<style scoped>

</style>
